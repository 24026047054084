import { useCultureSelectorData } from '@skyscanner-internal/global-components-3/header/helpers';
import { fetchCultureSelectorData } from '@skyscanner-internal/global-components-3/header/helpers/cultureSelectorDataFetch';
import Header from '@skyscanner-internal/global-components-3/header';

import config from '../../config';

import type { HeaderProps } from '@skyscanner-internal/global-components-3/header';

const HeaderWrapper = (existingProps: HeaderProps) => {
  const newProps = { ...existingProps };
  const cultureSelectorContext = useCultureSelectorData(
    fetchCultureSelectorData,
    existingProps.locale || 'en-GB',
    config.servePreReleasedLocales,
  );

  if (newProps.cultureSelectorProps) {
    newProps.cultureSelectorProps.currencies =
      cultureSelectorContext.currencies;
    newProps.cultureSelectorProps.markets = cultureSelectorContext.markets;
    newProps.cultureSelectorProps.locales = cultureSelectorContext.locales;
    newProps.cultureSelectorProps.enMarkets = cultureSelectorContext.enMarkets;
  }

  return (
    <Header
      {...newProps}
      isFetchingCultureSelectorData={
        cultureSelectorContext.isFetchingCultureSelectorData
      }
      onCultureSelectorButtonClick={
        cultureSelectorContext.onCultureSelectorButtonClick
      }
    />
  );
};

export default HeaderWrapper;
