import logger from 'saddlebag-logger';
import { redirect } from 'saddlebag-browser';
import { registerComponent } from 'saddlebag-componentregistry';
import { isLoggedIn, getProfilePicture } from 'saddlebag-user-management';

import loginModalInit from './login-modal-bootstrap';
import { loadExternalScripts } from './scriptLoader';

import type { HeaderProps } from './components/Header';

// For some reason doesn't work without .js
// eslint-disable-next-line import/extensions
import './public-path.js';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

// @ts-ignore Requires types from React, which hasn't been imported yet...
const showHeader = (headerElement, renderTarget, ReactDOM) => {
  if (IS_PRODUCTION) {
    ReactDOM.hydrate(headerElement, renderTarget);
  } else {
    ReactDOM.render(headerElement, renderTarget);
  }
};

(async () => {
  if (IS_PRODUCTION) {
    try {
      await loadExternalScripts();
    } catch (missing) {
      logger.logError({
        message: `Failed to load dependencies ${missing}`,
        level: 'error',
        component: 'header',
      });
    }
  }

  try {
    const React = await import(/* webpackMode: "eager" */ 'react');
    const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');

    const { default: Header } = await import(
      /* webpackMode: "eager" */ './components/Header'
    );
    if (process.env.NODE_ENV === 'development') {
      const { default: initialiseState } = await import(
        /* webpackMode: "eager" */ 'parse-state'
      );
      window.HEADER_STATE = initialiseState(
        window.location.href,
        true,
        window.HEADER_STATE,
      );
    }

    const windowHref = window.location.href.split('?')[0];

    window.HEADER_STATE.redirect = redirect;
    window.HEADER_STATE.redirectUrl = encodeURIComponent(windowHref);

    const renderHeader = (newProps = {}) => {
      const stateProps = (({
        activeTab,
        appleLoginPosition,
        componentVersion,
        cultureSelectorProps,
        currency,
        currencyLabel,
        isProductionEnv,
        locale,
        localeName,
        market,
        marketName,
        showBackgroundColor,
        showBorder,
        showCultureSelectorButton,
        showGoogleYolo,
        showSecondaryNav,
        showTabs,
        skipLinkHref,
        strings,
        unstable__EnableHeartIcon,
        urls,
        useCustomTheme,
        useMaxWidth,
        ...rest
      }) => ({
        activeTab,
        appleLoginPosition,
        componentVersion,
        cultureSelectorProps,
        currency,
        currencyLabel,
        isProductionEnv,
        locale,
        localeName,
        market,
        marketName,
        showBackgroundColor,
        showBorder,
        showCultureSelectorButton,
        showGoogleYolo,
        showSecondaryNav,
        showTabs,
        skipLinkHref,
        strings,
        urls,
        useCustomTheme,
        useMaxWidth,
        unstable__EnableHeartIcon,
        ...rest,
      }))(window.HEADER_STATE);

      const overrides = {
        userLoggedIn: isLoggedIn(),
        getProfilePicture,
        logger,
      };

      const headerElement = React.createElement(Header, {
        ...stateProps,
        ...newProps,
        ...overrides,
      });

      const renderTarget = document.getElementById('header-root');
      showHeader(headerElement, renderTarget, ReactDOM);
    };

    renderHeader();
    const { appleLoginPosition, showGoogleYolo } = window.HEADER_STATE;
    const experimentParams = { showGoogleYolo, appleLoginPosition };
    loginModalInit(experimentParams, renderHeader);

    // This function registers our component for potential re-hydration as some microsites
    // utilize the `awaitComponent` mechanism in conjunction with `registerComponent`
    // to hydrate or rehydrate the component at specific times. This ensures the
    // component is rendered or updated appropriately based on specific triggers or actions.
    // See https://skyscanner.atlassian.net/browse/ARGG-694 for more detail
    registerComponent({
      name: 'header',
      version: window.HEADER_STATE.componentVersion,
      componentDefinition: Header,
      props: window.HEADER_STATE,
      hydrate: (newProps: HeaderProps) => {
        renderHeader(newProps);
      },
    });
  } catch (err) {
    logger.logError({
      message: `Failed to render component ${err}`,
      level: 'error',
      component: 'header',
    });
  }
})();
